.avatar {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100vw;
  height: 100vh;
}

.img {
  object-fit: cover;
  object-position: top center;
  border-radius: 50%;
  filter: brightness(70%);
  width: 270px;
  height: 270px;
}

.name {
  text-align: center;
  margin-top: -60px;
  color: white;
  font-weight: bolder;
  font-size: 80px;
  z-index: 0;
  margin-left: 50px;
  margin-right: 50px;
}

@media (max-width: 500px) {
  .img {
    width: 245px;
    height: 245px;
  }

  .name {
    font-size: 60px;
  }
}
